export function useStaticPages() {
    const { getDynamicRoute } = useDynamicRoutes()

    const runtimeConfig = useRuntimeConfig()

    const staticPages = runtimeConfig.public.id.staticPage
    const getStaticPageUrl = (key: keyof typeof staticPages): string => {
        const id = Number(staticPages[key])
        return getDynamicRoute('static-page', id) as string
    }

    return {
        getStaticPageUrl,
    }
}
